.modal-box__title {
	text-align     : center;
	font-weight    : 800;
	font-size      : 18px;
	margin-bottom  : 18px;
	padding-bottom : 18px;
	border-bottom  : 1px solid #D8E1FD;
	line-height    : 1.3;
	@media screen and (min-width : 920px) {
		font-weight    : 900;
		font-size      : 26px;
		padding-bottom : 49px;
		margin-bottom  : 38px;
		position       : relative;
		background     : url("../images/blue-wave-header.svg") no-repeat 50% 70%;
	}
}

.modal-box__subtitle {
	font-size  : 14px;
	text-align : center;
	@media screen and (min-width : 920px) {
		font-size     : 16px;
		margin-bottom : 38px;
	}
}

.modal-box__footer {
	margin-top  : 18px;
	padding-top : 18px;
	border-top  : 1px solid #D8E1FD;
	text-align  : center;
	font-size   : 14px;
	@media screen and (min-width : 920px) {
		font-size     : 16px;
		padding-top   : 28px;
		margin-top    : 38px;
		padding-left  : 100px;
		padding-right : 100px;
	}
}

.modal-box__footer-inner {
	@media screen and (min-width : 920px) {
		width     : 500px;
		max-width : 100%;
	}
}

.modal-box__logo {
	margin-top : 10px;
	@media screen and (min-width : 920px) {
		margin-top : 18px;
	}
}