.wrapper {
	width              : 1240px;
	max-width          : 100%;
	margin             : auto;
	padding            : 0 20px;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
}

.wrapper-inner {
	margin             : auto;
	width              : 972px;
	max-width          : 100%;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	@media screen and (min-width : 920px) {
		&.is-padding {
			padding : 0 20px;
		}
	}
}

.wrapper-text {
	@media screen and (min-width : 580px) {
		width     : 700px;
		max-width : 100%;
		margin    : auto;
	}
}