// Color
$sf-black: #000000;
$white: #ffffff;
$sf-blue: #0428a8;
$sf-link-blue: #062ca6;
$sf-light-blue: #e4f5f9;
$sf-grey: #77787A;

// Variables
// ---------
$body-font-size : 16px;
$body-font : 'Font-Montserrat', system-ui, sans-serif;
$body-line-height : 1.5;
$body-color : $sf-black;
$body-background : #FFF;


// Table
$table-header-font-weight : 700;
$table-cell-padding : 5px 10px;

// Text
$heading-font-weight : 800;
$heading-line-height : 1.2;
$heading-margin : 20px 0;
$paragraph-margin : 0 0 20px;
$list-margin : 20px 0;
$h1 : 28px;
$h2 : 24px;
$h3 : 20px;
$h4 : 18px;
$h5 : 16px;
$bold-font-weight : 700;
$space-1 : 1em;
$space-2 : 2em;
$space-3 : 3em;
$text-small : 14px;

// Color
$main : $body-color;
$red : #E81313;
$link-color : $sf-link-blue;
$link-color-hover : $sf-link-blue;
