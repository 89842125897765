.directions {
	margin-bottom : 20px;
	@media screen and (min-width : 920px) {
		margin-bottom : 68px
	}
}

.directions__wrapper {
	@media screen and (min-width : 920px) {
		background    : $sf-light-blue;
		border-radius : 8px;
		padding       : 25px 0;
		overflow      : hidden;
	}
	@media screen and (min-width : 1115px) {
		padding : 78px 0
	}
}

.directions__grid {
	@media screen and (min-width : 635px) {
		margin          : -5px;
		display         : flex;
		flex-flow       : row nowrap;
		justify-content : space-between;
		align-items     : stretch;
	}
	@media screen and (min-width : 920px) {
		margin : -33px;
	}
}

.directions__item {
	margin-top : 20px;
	@media screen and (min-width : 635px) {
		width   : 33.3333333332%;
		margin  : 0;
		padding : 5px;
	}
	@media screen and (min-width : 920px) {
		padding            : 33px;
		-webkit-box-sizing : border-box;
		-moz-box-sizing    : border-box;
		box-sizing         : border-box;
		position           : relative;
		&:after {
			content       : '';
			z-index       : 1;
			background    : #FFF;
			border-radius : 8px;
			top           : 33px;
			bottom        : 33px;
			left          : 33px;
			right         : 33px;
			position      : absolute;
		}
		&:before {
			content    : '';
			position   : absolute;
			left       : 29px;
			width      : 4px;
			height     : 60%;
			top        : 50%;
			background : $sf-blue;
			transform  : translateY(-50%);
		}
	}
}

.directions__inner {
	height          : 265px;
	position        : relative;
	border-radius   : 8px;
	display         : block;
	text-decoration : none;
	@media screen and (min-width : 560px) {
		height : 200px;
	}
	@media screen and (min-width : 920px) {
		height      : auto;
		padding-top : 176px;
	}
}

.directions__image {
	position                : absolute;
	top                     : 0;
	left                    : 0;
	right                   : 0;
	bottom                  : 0;
	background-position     : 50% 0;
	background-repeat       : no-repeat;
	-webkit-background-size : cover;
	background-size         : cover;
	border-radius           : 8px;
	z-index                 : 5;
	display                 : block;
	@media screen and (min-width : 920px) {
		height : 190px;
		bottom : auto;
		&:after {
			content  : '';
			position : absolute;
			bottom   : 30px;
			left     : 30px;
			@include svg-wave-item;
		}

	}
}

.directions__main {
	padding            : 20px 25px;
	bottom             : 0;
	left               : 0;
	right              : 0;
	position           : absolute;
	z-index            : 10;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	display            : block;
	@media screen and (min-width : 920px) {
		border-radius : 8px;
		background    : #FFF;
		padding       : 20px 30px 25px;
		position      : relative;
		bottom        : auto;
		top           : auto;
	}
}

.directions__title {
	font-size      : 22px;
	font-weight    : 900;
	letter-spacing : -0.2px;
	display        : block;
	color          : $white;
	@media screen and (min-width : 920px) {
		color       : #000;
		font-weight : 800;
		line-height : 1.1;
	}
}

.directions__link {
	display : block;

	span {
		color           : #FFF;
		text-decoration : underline;

		@media screen and (min-width : 920px) {
			font-size   : 14px;
			line-height : 1.71;
			color       : $sf-link-blue
		}

		&:hover {
			text-decoration : none;
		}
	}
}

.directions__text {
	display    : none;
	visibility : hidden;
	@media screen and (min-width : 920px) {
		display     : block;
		visibility  : visible;
		font-size   : 14px;
		line-height : 1.57;
		color       : #000;
		margin      : 8px 0;
	}
}

.directions__panel {
	height          : 140px;
	position        : relative;
	border-radius   : 8px;
	display         : block;
	text-decoration : none;
	@media screen and (min-width : 560px) {
		height : 200px;
	}
	@media screen and (min-width : 920px) {
		height : 220px;
		.directions__image {
			bottom       : 0;
			height       : auto;
			margin-right : 320px;

			&:after {
				background  : url("../images/big-wave.svg") no-repeat 0 0;
				width       : 406px;
				height      : 19px;
				bottom      : 35px;
				left        : 50%;
				transform   : translateX(-50%);
				margin-left : -20px;
				transition: background-position .3s ease;
			}
		}
		.directions__main {
			width              : 346px;
			position           : absolute;
			top                : 0;
			bottom             : 0;
			right              : 0;
			left               : auto;
			display            : flex;
			flex-flow          : column nowrap;
			justify-content    : center;
			align-items        : center;
			-webkit-box-sizing : border-box;
			-moz-box-sizing    : border-box;
			box-sizing         : border-box;
		}
		&:before {
			content    : '';
			position   : absolute;
			bottom     : -4px;
			height     : 4px;
			width      : 60%;
			left       : 50%;
			background : $sf-blue;
			transform  : translateX(-50%);
		}
	}
}

.directions__info {
	margin : 20px 0;
}