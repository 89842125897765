// -----------------------------------------------
//  Button config
// -----------------------------------------------

$btn-font-weight : 700;
$btn-line-height : 1.25;
$btn-border-width : 1px;
$btn-padding : 14px 10px;
$btn-font-size : 14px;
$btn-border-radius : 8px;
$btn-transition : background-color .3s ease;

// OUTLINE

// SIZE
// Big
$btn-padding-big : 14px 10px;
$btn-font-size-big : 16px;

// Small
$btn-padding-small : 8px 10px;
$btn-font-size-small : 13px;

// COLOR
// White
$btn-white-bg : #FFF;
$btn-white-color : $sf-blue;
$btn-white-bg-hover : $sf-blue;
$btn-white-color-hover : #FFF;

// Link
$btn-link-bg : transparent;
$btn-link-color : $sf-blue;
$btn-link-bg-hover : #F7F7F7;
$btn-link-color-hover : lighten($sf-blue, 10%);

// Blue
$btn-blue-bg : $sf-blue;
$btn-blue-color : #FFF;
$btn-blue-color-outline : $btn-blue-bg;
$btn-blue-bg-hover : lighten($sf-blue, 10%);
$btn-blue-color-hover : #FFF;

// LOADING
$btn-loading-color : #FFF;
$btn-loading-color-outline : $sf-blue;

// SPACING
$btn-spacing-x : 5px;
$btn-spacing-y : 5px;

// -----------------------------------------------
//  Button style
// -----------------------------------------------

.b-btn {
	display            : inline-block;
	font-weight        : $btn-font-weight;
	line-height        : $btn-line-height;
	text-align         : center;
	white-space        : nowrap;
	vertical-align     : middle;
	user-select        : none;
	padding            : $btn-padding;
	font-size          : $btn-font-size;
	border-radius      : $btn-border-radius;
	transition         : $btn-transition;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	text-decoration    : none;
	position           : relative;
	cursor             : pointer;

	&:hover,
	&:focus,
	&.focus {
		outline : 0;
	}

	&.disabled,
	&:disabled {
		cursor     : default;
		opacity    : .45;
		box-shadow : none;
		background : inherit;
	}

	&:active,
	&.active {

	}

	[class^="b-icon-"],
	[class*=" b-icon-"] {
		vertical-align : middle;
	}

	// SIZE
	// Big
	&.big {
		padding   : $btn-padding-big;
		font-size : $btn-font-size-big;
	}

	&.small {
		padding   : $btn-padding-small;
		font-size : $btn-font-size-small;
	}

	// COLOR
	// White
	&.white {
		background-color : $btn-white-bg;
		color            : $btn-white-color;
		border           : $btn-border-width solid $btn-white-bg;

		&:hover,
		&:focus,
		&:active,
		&.active {
			border-color     : $btn-white-bg-hover;
			background-color : $btn-white-bg-hover;
			color            : $btn-white-color-hover;

			.b-icon-blue-wave {
				@include svg-wave;
			}
		}

		&.disabled,
		&:disabled {
			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color : $btn-white-bg;
				color            : $btn-white-color;
			}
		}
	}

	// Link
	&.link {
		background-color : $btn-link-bg;
		color            : $btn-link-color;
		border           : $btn-border-width solid $btn-link-bg;

		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color : $btn-link-bg-hover;
			color            : $btn-link-color-hover;
		}

		&.disabled,
		&:disabled {
			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color : $btn-link-bg;
				color            : $btn-link-color;
			}
		}
	}

	// Blue
	&.blue {
		background-color : $btn-blue-bg;
		color            : $btn-blue-color;
		border           : $btn-border-width solid $btn-blue-bg;

		&:hover,
		&:focus,
		&:active,
		&.active {
			border-color     : $btn-blue-bg-hover;
			background-color : $btn-blue-bg-hover;
			color            : $btn-blue-color-hover;
		}

		&.disabled,
		&:disabled {
			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color : $btn-blue-bg;
				color            : $btn-blue-color;
			}
		}
	}

	// OUTLINE
	&.outline {
		border : 1px solid transparent;

		// White
		&.white {
			border-color     : $btn-white-bg;
			background-color : transparent;

			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color : $btn-white-bg-hover;
				color            : $btn-white-color-hover;
			}

			&.disabled,
			&:disabled {
				&:hover,
				&:focus,
				&:active,
				&.active {
					border-color     : $btn-white-bg;
					background-color : transparent;
				}
			}
		}

		// Blue
		&.blue {
			border-color     : $btn-blue-bg;
			background-color : transparent;
			color            : $btn-blue-color-outline;

			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color : $btn-blue-bg-hover;
				border-color     : $btn-blue-bg-hover;
				color            : $btn-blue-color-hover;

				.b-icon-blue-wave {
					@include svg-wave;
				}
			}

			&.disabled,
			&:disabled {
				&:hover,
				&:focus,
				&:active,
				&.active {
					border-color     : $btn-blue-bg;
					background-color : transparent;
					color            : $btn-blue-color-outline;
				}
			}
		}
	}

	// LOADING
	&.loading {
		&:before {
			content          : '';
			border-radius    : 50%;
			width            : 12px;
			height           : 12px;
			border           : 3px solid rgba($btn-loading-color, 0.6);
			border-top-color : $btn-loading-color;
			display          : inline-block;
			vertical-align   : middle;
			margin-right     : 10px;
			margin-left      : -15px;
			transition       : border .3s ease;
			animation        : loadingSpin 1s infinite linear;
		}
	}

	// FULL-SIZE
	&.full-size {
		width   : 100%;
		display : block;

		+ .full-size {
			margin-right : $btn-spacing-y;
		}
	}

	// FULL-SIZE
	&.min-w {
		width     : 320px;
		max-width : 100%;
	}

	// SPACING
	& + .b-btn {
		margin-left : $btn-spacing-x;
	}
}

.b-btn-grouping {
	display : inline-block;

	.b-btn {
		margin-right  : 0;
		float         : left;
		border-radius : 0;

		&:first-child {
			border-radius : $btn-border-radius 0 0 $btn-border-radius;
		}

		&:last-child {
			border-radius : 0 $btn-border-radius $btn-border-radius 0;
		}

		&.outline {
			margin-left : -1px;
			z-index     : 1;

			&:hover {
				z-index : 2;
			}

			&.active {
				z-index : 3;
			}

			&:first-child {
				margin-left : 0;
			}
		}
	}
}

@keyframes loadingSpin {
	0% {
		transform : rotate(0deg);
	}
	100% {
		transform : rotate(360deg);
	}
}

@keyframes wave-animation {
	0% {
		opacity   : 0;
		transform : translate(-50%, -50%) scale(0);
	}
	50% {
		opacity   : 1;
		transform : translate(-50%, -50%) scale(.5);
	}
	100% {
		opacity   : 0;
		transform : translate(-50%, -50%) scale(1);
	}
}

// Future-proof disabling of clicks on `<a>` elements
a.b-btn.disabled,
fieldset[disabled] a.b-btn {
	pointer-events : none;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.full-size {
		width : 100%;
	}
}