.age-block {
	width            : 43px;
	height           : 43px;
	display          : inline-block;
	vertical-align   : top;
	background-color : #EEF8F8;
	border-radius    : 50%;
	line-height      : 43px;
	font-size        : 14px;
	font-weight      : 800;
	text-align       : center;
	letter-spacing   : 0.4px;
	color            : $sf-blue;
}