body {
	padding     : 0;
	margin      : 0;
	color       : $body-color;
	line-height : $body-line-height;
	font-family : $body-font;
	font-size   : $body-font-size;
	background  : $body-background;
	min-width   : 320px;
	@media screen and (min-width : 920px) {
		font-size   : 18px;
		line-height : 1.56;
		background  : #F9FEFF;
	}
}

// Images
img {
	max-width      : 100%;
	height         : auto;
	vertical-align : top;
	border         : 0;
}

svg {
	max-height : 100%
}

select:not([multiple]) {
	vertical-align : middle;
}

// Table
table {
	border-collapse : separate;
	border-spacing  : 0;
	max-width       : 100%;
	width           : 100%;
}

th {
	text-align  : left;
	font-weight : $table-header-font-weight;
}

th,
td {
	padding     : $table-cell-padding;
	line-height : inherit;
}

th {
	vertical-align : bottom
}

td {
	vertical-align : top
}

// Text
a {
	outline         : 0;
	text-decoration : underline;
	color           : $link-color;

	&:hover {
		color           : $link-color-hover;
		text-decoration : none;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight : $heading-font-weight;
	line-height : $heading-line-height;
	margin      : $heading-margin;
	@media screen and (min-width : 920px) {
		margin : 35px 0;
	}

	&:first-child {
		margin-top : 0;
	}
}

p {
	margin : $paragraph-margin;
	@media screen and (min-width : 920px) {
		margin : 0 0 35px;
	}

	&:last-child {
		margin-bottom : 0;
	}
}

dl,
ol,
ul {
	margin : $list-margin;
}

ol {
	list-style : decimal;
}

h1 {
	font-size : $h1;
}

h2 {
	font-size : $h2;
}

h3 {
	font-size : $h3;
}

h4 {
	font-size : $h4;
}

h5 {
	font-size : $h5;
}

hr {
	margin     : 30px 0;
	display    : block;
	height     : 1px;
	background : #BCCEDF;
	border     : 0;
	@media screen and (min-width : 920px) {
		margin : 35px 0;
	}
}

.text-small {
	font-size : $text-small;
}

::-moz-focus-inner {
	border  : 0;
	padding : 0;
}

.left-align {
	text-align : left
}

.center {
	text-align : center
}

.right-align {
	text-align : right
}

.justify {
	text-align : justify
}

.nowrap {
	white-space : nowrap
}

.break-word {
	word-wrap : break-word
}

.list-reset {
	list-style   : none;
	padding-left : 0;
}

.clearfix {
	@include clearfix;
}

// Clear for old browser <div class="clr"></div>
.clr {
	clear : both;
}

.hidden-box {
	display    : none;
	visibility : hidden;
}