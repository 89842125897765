.article__title {
	font-size     : 22px;
	font-weight   : 900;
	line-height   : 1.32;
	color         : $sf-black;
	margin-bottom : 10px;
	order         : 0;
	@media screen and (min-width : 580px) {
		font-size     : 38px;
		font-weight   : 900;
		margin-bottom : 48px;
		text-align    : center;
	}
}

.article__gallery {
	margin : 20px 0;
	@media screen and (min-width : 920px) {
		margin : 35px 0;
	}
	@media screen and (min-width : 1010px) {
		margin : 35px -135px;
	}
}


.article__footer {
	text-align : center;
}