.feature {
	@media screen and (min-width : 580px) {
		margin  : 68px 0 40px;
		padding : 0;
	}
}

.feature__inner {
	margin-top  : 20px;
	padding-top : 15px;
	border-top  : 1px solid #BCCEDF;

	@media screen and (min-width : 580px) {
		display     : flex;
		flex-flow   : column nowrap;
		border-top  : 0;
		padding-top : 0;
		margin-top  : 0;
	}
}

.feature__separator {
	margin         : 30px 0;
	padding-bottom : 22px;
	border-bottom  : 1px solid #BCCEDF;
	text-align     : center;
	@media screen and (min-width : 580px) {
		padding-bottom : 0;
	}

	i {
		vertical-align : bottom;
		margin         : 0 10px;
	}
}

.feature__title {
	font-weight   : 800;
	font-size     : 16px;
	margin-bottom : 15px;
	@media screen and (min-width : 580px) {
		font-weight   : 900;
		font-size     : 26px;
		text-align    : center;
		margin-bottom : 48px;
	}
}

.feature__link {
	margin-top : 30px;
	text-align : center;
	@media screen and (min-width : 580px) {
		order         : 4;
		margin-bottom : 68px;
	}
}

.feature__footer {
	@media screen and (min-width : 580px) {
		order : 5;
		p:last-child {
			margin : 0;
		}
	}
}

.feature__gallery {
	margin-top : 48px;
}