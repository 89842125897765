.footer {
    padding : 30px 0;
    @media screen and (min-width : 920px) {
        padding    : 85px 0;
        color      : #FFF;
        background : $sf-blue;
    }

    .wrapper {
        display   : flex;
        flex-flow : column nowrap;
        @media screen and (min-width : 920px) {
            flex-flow       : row nowrap;
            justify-content : space-between;
        }
    }
}

.footer__copyright {
    text-align : center;
    order      : 1;
    @media screen and (min-width : 920px) {
        order        : inherit;
        flex         : 0 0 145px;
        margin-right : 20px;
    }
}

.footer__logo {
    display        : inline-block;
    vertical-align : top;
}


.footer__subtitle {
    font-size   : 14px;
    line-height : 1.57;
    margin-top  : 0;
    @media screen and (min-width : 920px) {
        padding-bottom : 17px;
        position       : relative;
        &:after {
            content   : '';
            position  : absolute;
            bottom    : 0;
            left      : 50%;
            transform : translateX(-50%);
            @include svg-wave-item;
        }
    }
}

.footer__years {
    margin-top  : 5px;
    font-size   : 12px;
    line-height : 1.33;
    @media screen and (min-width : 920px) {
        margin-top : 9px;
    }
}

.footer__cell {
    @media screen and (min-width : 920px) {
        margin : 0 20px;
    }
}

.footer__info {
    order       : 3;
    border-top  : 1px solid #BCCEDF;
    margin-top  : 20px;
    padding-top : 20px;
    @media screen and (min-width : 920px) {
        order       : inherit;
        margin-top  : 0;
        flex        : 0 0 165px;
        border      : 0;
        padding-top : 0;
    }
}

.footer__social {
    order      : 2;
    text-align : center;
    margin-top : 20px;
    @media screen and (min-width : 920px) {
        order       : inherit;
        margin-top  : 0;
        text-align  : left;
        flex        : 0 0 200px;
        margin-left : 20px;
    }
    @media screen and (min-width : 1075px) {
        flex : 0 0 244px;
    }
}

.footer__row {
    @media screen and (min-width : 920px) {
        display         : flex;
        flex-flow       : row nowrap;
        justify-content : space-between;
        align-items     : center;

        .age-block {
            float : left;
        }

        .social-block {
            float : right;

            a {
                background : #FFF;

                .b-icon-vk {
                    @include svg-blue-vk;
                }

                .b-icon-fb {
                    @include svg-blue-fb;
                }

                .b-icon-twitter {
                    @include svg-blue-twitter;
                }

                &:hover {
                    background : darken(#FFF, 10%);
                }
            }
        }
    }
}

.developer {
    display         : flex;
    justify-content : center;
    align-items     : stretch;
    @media screen and (min-width : 920px) {
        margin-top : 30px;
        margin-left: -30px;
    }

    .b-icon-dev {
        margin-right : 6px;
    }
    @media screen and (min-width : 920px) {
        font-size : 12px;
    }
    span {
        line-height : 125%;
        color       : $sf-blue;
        display: inline-block;
        @media screen and (min-width : 920px) {
            color : #FFF;
        }
    }

    a {
        @media screen and (min-width : 920px) {
            color     : #FFF;
        }


        &:hover {
            @media screen and (min-width : 920px) {
                text-decoration : underline;
            }

            span {
                text-decoration : underline;
                @media screen and (min-width : 920px) {
                    text-decoration : none;
                }
            }
        }
    }
}

.footer__content {
    @media screen and (min-width : 920px) {
        margin-top  : 50px;
        font-size   : 12px;
        font-weight : 200;
        line-height : 1.33;
        color       : rgba(255, 255, 255, 0.98);
    }
}

.footer__cell {
    ul {
        margin     : 0;
        padding    : 0;
        list-style : none;

        li {
            margin-top : 5px;
            font-size  : 14px;

            &:first-child {
                margin-top : 0;
            }

            &:after {
                display : none;
            }

            > a {
                text-decoration : none;
                color           : #FFF;
            }

            &:hover,
            &.active {
                > a {
                    text-decoration : underline;
                }
            }
        }
    }
}
