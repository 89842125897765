/**
 * Swiper 3.4.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: December 13, 2016
 */
.swiper-container {
	margin-left  : auto;
	margin-right : auto;
	position     : relative;
	overflow     : hidden;
	z-index      : 1;
}

.swiper-container-no-flexbox .swiper-slide {
	float : left;
}

.swiper-container-vertical > .swiper-wrapper {
	flex-direction         : column;
}

.swiper-wrapper {
	position                    : relative;
	width                       : 100%;
	height                      : 100%;
	z-index                     : 1;
	display                     : flex;
	transition-property         : transform;
	-webkit-box-sizing          : content-box;
	-moz-box-sizing             : content-box;
	box-sizing                  : content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	-webkit-transform : translate3d(0px, 0, 0);
	-moz-transform    : translate3d(0px, 0, 0);
	-o-transform      : translate(0px, 0px);
	-ms-transform     : translate3d(0px, 0, 0);
	transform         : translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap         : wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function         : ease-out;
	margin                             : 0 auto;
}

.swiper-slide {
	flex-shrink         : 0;
	width               : 100%;
	height              : 100%;
	position            : relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
	height : auto;
}

.swiper-container-autoheight .swiper-wrapper {
	align-items                 : flex-start;
	transition-property         : transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
	position       : absolute;
	left           : 0;
	top            : 0;
	pointer-events : none;
	opacity        : 0;
	z-index        : -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	-ms-touch-action : pan-y;
	touch-action     : pan-y;
}

.swiper-wp8-vertical {
	-ms-touch-action : pan-x;
	touch-action     : pan-x;
}
