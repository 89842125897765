.is-mobile-hidden {
	display    : none;
	visibility : hidden;
	@media screen and (min-width : 920px) {
		display    : block;
		visibility : visible;
	}
}

.is-desktop-hidden {
	display    : block;
	visibility : visible;
	@media screen and (min-width : 920px) {
		display    : none;
		visibility : hidden;
	}
}