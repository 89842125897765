[class^="b-icon-"],
[class*=" b-icon-"] {
	display : inline-block;
}

.b-icon-vk {
	@include svg-vk;
}

.b-icon-fb {
	@include svg-fb;
}

.b-icon-twitter {
	@include svg-twitter;
}

.b-icon-black-adult {
	@include svg-black-adult;
}

.b-icon-black-child {
	@include svg-black-child;
}

.b-icon-black-surfing {
	@include svg-black-surfing;
}

.b-icon-blue-search {
	@include svg-blue-search;
}

.b-icon-blue-arrow-left {
	@include svg-blue-arrow-left;
}

.b-icon-blue-arrow-right {
	@include svg-blue-arrow-right;
}

.b-icon-blue-close {
	@include svg-blue-close;
}

.b-icon-close-small {
	@include svg-close-small;
}

.b-icon-blue-wave {
	@include svg-blue-wave;
}

.b-icon-wave {
	@include svg-wave;
}

.b-icon-wave-item {
	@include svg-wave-item;
}

.b-icon-wave {
	@include svg-wave;
}

.b-icon-dev {
	@include svg-dev;
}

.b-icon-white-dev {
	@include svg-white-dev;
}

