body.oc-loading,
body.oc-loading * {
	cursor : wait !important
}

.stripe-loading-indicator {
	height     : 5px;
	background : transparent;
	position   : fixed;
	top        : 0;
	left       : 0;
	width      : 100%;
	overflow   : hidden;
	z-index    : 2000
}

.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {
	height             : 5px;
	display            : block;
	background         : $sf-blue;
	position           : absolute;
}

.stripe-loading-indicator .stripe {
	width             : 100%;
	-webkit-animation : oc-infinite-loader 60s linear;
	animation         : oc-infinite-loader 60s linear
}

.stripe-loading-indicator .stripe-loaded {
	width     : 100%;
	transform : translate3d(-100%, 0, 0);
	opacity   : 0;
	filter    : alpha(opacity=0)
}

.stripe-loading-indicator.loaded {
	opacity                  : 0;
	filter                   : alpha(opacity=0);
	-webkit-transition       : opacity 0.4s linear;
	transition               : opacity 0.4s linear;
	-webkit-transition-delay : 0.3s;
	transition-delay         : 0.3s
}

.stripe-loading-indicator.loaded .stripe {
	animation-play-state : paused
}

.stripe-loading-indicator.loaded .stripe-loaded {
	opacity            : 1;
	filter             : alpha(opacity=100);
	transform          : translate3d(0, 0, 0);
	-webkit-transition : transform 0.3s linear;
	transition         : transform 0.3s linear
}

.stripe-loading-indicator.hide {
	display : none
}
