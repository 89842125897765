.wather {
	border-radius           : 8px;
	background-repeat       : no-repeat;
	background-position     : 50% 50%;
	-webkit-background-size : cover;
	background-size         : cover;
	min-height              : 205px;
	position                : relative;
	color                   : #FFF;
}

.wather__title {
	padding            : 14px 20px;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	border-radius      : 5px 5px 0 0;
	backdrop-filter    : blur(6px);
	background-color   : rgba(255, 169, 169, 0.45);
	font-weight        : 800;
	font-size          : 14px;
	display            : flex;
	flex-flow          : row nowrap;
	justify-content    : space-between;
	margin-bottom      : 6px;

	.no-backdropfilter & {
		background-color : rgba(255, 169, 169, 0.75);
	}

	img {
		margin-left : 10px;
	}
}

.wather__main {
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	padding            : 18px 20px;
	border-radius      : 5px;
	backdrop-filter    : blur(6px);
	background-color   : rgba(0, 168, 167, 0.22);

	.no-backdropfilter & {
		background-color : rgba(0, 168, 167, 0.82);
	}
}

.wather__flex {
	display         : flex;
	flex-flow       : row nowrap;
	justify-content : space-between;
	align-items     : center;
	margin-bottom   : 7px;
}

.wather__temp {
	font-size    : 28px;
	font-weight  : 900;
	margin-right : 10px;
}

.wather__time {
	font-size   : 13px;
	font-weight : 700;
	line-height : 1.85;

	b {
		font-weight : 800;
	}
}

.wather__row {
	margin-top  : 7px;
	font-weight : 700;
	font-size   : 14px;
	@media screen and (min-width : 920px) {
		font-size : 16px;
	}
}