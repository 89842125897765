@mixin svg-black-adult() {
	background: url('../images/sprite.svg') 0 80.21978021978022% no-repeat;
	width: 52px;
	height: 51px;
}
@mixin svg-black-child() {
	background: url('../images/sprite.svg') 68.81720430107526% 12.222222222222221% no-repeat;
	width: 60px;
	height: 52px;
}
@mixin svg-black-surfing() {
	background: url('../images/sprite.svg') 0 13.75% no-repeat;
	width: 64px;
	height: 62px;
}
@mixin svg-blue-arrow-left() {
	background: url('../images/sprite.svg') 95.25986892242037% 23.13284864507601% no-repeat;
	width: 21.78px;
	height: 20.96px;
}
@mixin svg-blue-arrow-right() {
	background: url('../images/sprite.svg') 95.25986892242037% 40.449438202247194% no-repeat;
	width: 21.78px;
	height: 20.96px;
}
@mixin svg-blue-close() {
	background: url('../images/sprite.svg') 93.98496240601504% 79.60975609756098% no-repeat;
	width: 20px;
	height: 19px;
}
@mixin svg-blue-fb() {
	background: url('../images/sprite.svg') 78.32167832167832% 59.34959349593496% no-repeat;
	width: 10px;
	height: 19px;
}
@mixin svg-blue-search() {
	background: url('../images/sprite.svg') 100% 0 no-repeat;
	width: 28px;
	height: 28px;
}
@mixin svg-blue-twitter() {
	background: url('../images/sprite.svg') 11.764705882352942% 96.875% no-repeat;
	width: 17px;
	height: 14px;
}
@mixin svg-blue-vk() {
	background: url('../images/sprite.svg') 94.6969696969697% 54.625% no-repeat;
	width: 21px;
	height: 14px;
}
@mixin svg-blue-wave() {
	background: url('../images/sprite.svg') 56.63716814159292% 46.666666666666664% no-repeat;
	width: 40px;
	height: 7px;
}
@mixin svg-checkbox() {
	background: url('../images/sprite.svg') 35.714285714285715% 95.38461538461539% no-repeat;
	width: 13px;
	height: 12px;
}
@mixin svg-close-small() {
	background: url('../images/sprite.svg') 0 100% no-repeat;
	width: 16px;
	height: 18px;
}
@mixin svg-dev() {
	background: url('../images/sprite.svg') 42.27642276422764% 72.27722772277228% no-repeat;
	width: 30px;
	height: 41px;
}
@mixin svg-fb() {
	background: url('../images/sprite.svg') 78.32167832167832% 74.79674796747967% no-repeat;
	width: 10px;
	height: 19px;
}
@mixin svg-twitter() {
	background: url('../images/sprite.svg') 24.264705882352942% 96.875% no-repeat;
	width: 17px;
	height: 14px;
}
@mixin svg-vk() {
	background: url('../images/sprite.svg') 94.6969696969697% 65.5625% no-repeat;
	width: 21px;
	height: 14px;
}
@mixin svg-wave() {
	background: url('../images/sprite.svg') 46.017699115044245% 84.44444444444444% no-repeat;
	width: 40px;
	height: 7px;
}
@mixin svg-wave-item() {
	background: url('../images/sprite.svg') 0 0 no-repeat;
	width: 125px;
	height: 11px;
}
@mixin svg-white-dev() {
	background: url('../images/sprite.svg') 66.66666666666667% 72.27722772277228% no-repeat;
	width: 30px;
	height: 41px;
}
