.header {
	padding : 20px 0;

	.wrapper {
		display         : flex;
		flex-flow       : row nowrap;
		justify-content : space-between;
		@media screen and (min-width : 920px) {
			display       : block;
			position      : relative;
			padding-right : 65px;
		}
		@media screen and (min-width : 1010px) {
			padding-right : 75px;
		}
	}

	@media screen and (min-width : 920px) {
		background    : #FFF;
		box-shadow    : 0 2px 11px #C5D1D3;
		margin-bottom : 20px;
	}
}

@keyframes panelDrop {
	0% {
		opacity   : 0;
		transform : scaleY(0);
	}
	100% {
		opacity   : 1;
		transform : scaleY(1);
	}
}

@keyframes searchDrop {
	0% {
		opacity   : 0;
		transform : translateY(-20px);
	}
	100% {
		opacity   : 1;
		transform : translateY(0px);
	}
}

.header__dropdown {
	position           : absolute;
	top                : 0;
	left               : 0;
	right              : 0;
	background         : #FFF;
	box-shadow         : 0 2px 11px #C5D1D3;
	display            : none;
	flex-flow          : column nowrap;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	padding            : 50px 20px 40px;
	transform-origin   : 0 0;
	z-index            : 100;

	@media screen and (min-width : 920px) {
		position         : relative;
		background-color : transparent;
		padding          : 0;
		box-shadow       : none;
		display          : flex;
		flex-flow        : row nowrap;
		justify-content  : space-between;
		align-items      : center;
		z-index          : 1;
	}

	&.is-active {
		display   : flex;
		animation : panelDrop .4s ease;
	}
}

.header__search {
	button {
		vertical-align : top;
		cursor         : pointer;
		border         : 0;
		outline        : 0;
		margin-right   : -20px;
		padding        : 0 20px 0 0;
		background     : transparent;
		position       : relative;
		@media screen and (min-width : 920px) {
			position     : absolute;
			top          : 22px;
			right        : 0;
			margin-right : 0;
		}

		.b-icon-blue-close {
			position   : absolute;
			top        : 4px;
			right      : 25px;
			opacity    : 0;
			transition : opacity .1s ease;
		}

		&.is-active {
			.b-icon-blue-search {
				opacity : 0;
			}

			.b-icon-blue-close {
				opacity : 1;
			}
		}
	}
}

.header-search {
	position         : absolute;
	top              : 68px;
	left             : 0;
	right            : 0;
	padding          : 20px 0;
	display          : none;
	background       : $sf-light-blue;
	transform-origin : 0 0;
	z-index          : 100;

	@media screen and (min-width : 920px) {
		top     : 110px;
		padding : 80px 0;
	}

	&.is-active {
		display   : block;
		animation : searchDrop .4s ease;
	}

	form {
		display  : block;
		position : relative;
		width    : 100%;
	}
}

input.header-search__input {
	width        : 100%;
	position     : relative;
	z-index      : 5;
	padding-left : 56px;

	&::-ms-clear,
	&::-webkit-search-cancel-button {
		display : none;
	}
}

.header-search__button {
	position   : absolute;
	top        : 0;
	left       : 0;
	bottom     : 0;
	border     : 0;
	background : transparent;
	outline    : 0;
	padding    : 0 0 0 16px;
	z-index    : 10;
	@include hide-text;

	i {
		transform : scale(0.929);
	}
}

.header__menu {
	order : 0;
	@media screen and (min-width : 920px) {
		order : 1;
	}
}

.header-info {
	order         : 1;
	margin        : 20px 0;
	padding       : 20px 0;
	border-top    : 1px solid #C6D4E2;
	border-bottom : 1px solid #C6D4E2;
	font-weight   : 700;
	font-size     : 14px;
	@media screen and (min-width : 920px) {
		order   : 2;
		border  : 0;
		padding : 0;
		margin  : 5px 0 0;
		color   : $sf-blue;
	}
	@media screen and (min-width : 1120px) {
		margin : 0;
	}

	a {
		color           : $sf-black;
		text-decoration : none;
		@media screen and (min-width : 920px) {
			color : $sf-blue;
		}

		&:hover {
			text-decoration : underline;
		}
	}
}

.header-info__row {
	margin-top : 15px;
	@media screen and (min-width : 920px) {
		margin : 0;
	}
	@media screen and (min-width : 1120px) {
		margin         : 0;
		display        : inline-block;
		vertical-align : top;
		&:not(:first-child) {
			position     : relative;
			padding-left : 18px;
			margin-left  : 19px;

			&:after {
				content    : '';
				position   : absolute;
				top        : -1px;
				height     : 24px;
				width      : 1px;
				background : $sf-blue;
				left       : 0;
			}
		}
	}

	&:first-child {
		margin-top : 0;
	}
}

.header-logo {
	order           : 2;
	display         : block;
	text-decoration : none;
	text-align      : center;
	@media screen and (min-width : 920px) {
		order      : 0;
		flex       : 0 0 78px;
		margin-top : -10px;
	}
}

.header-logo__slogan {
	display     : block;
	font-size   : 14px;
	line-height : 1.57;
	color       : $sf-black;
	@media screen and (min-width : 920px) {
		@include hide-text;
	}
}

.header-logo__copyright {
	display     : block;
	font-size   : 12px;
	line-height : 1.33;
	margin-top  : 6px;
	color       : $sf-black;
	@media screen and (min-width : 920px) {
		@include hide-text;
		margin : 0;
	}
}

.header__social {
	order      : 3;
	text-align : center;
	margin-top : 20px;
	@media screen and (min-width : 920px) {
		order      : 3;
		margin-top : 3px;
	}
	@media screen and (min-width : 1120px) {
		margin-top : 0;
	}

	&.is-mobile {
		order      : inherit;
		margin-top : 0;
		@media screen and (min-width : 920px) {
			display    : none;
			visibility : hidden;
		}
	}
}

.header__age {
	order      : 4;
	text-align : center;
	margin-top : 30px;
	@media screen and (min-width : 920px) {
		display    : none;
		visibility : hidden;
	}
}

.header-info__footer {
	display    : none;
	visibility : hidden;
	@media screen and (min-width : 920px) {
		display    : flex;
		flex-flow  : column nowrap;
		visibility : visible;
		color      : #FFF;
		font-size  : 14px;
		a {
			color           : #FFF;
			text-decoration : none;

			&:hover {
				text-decoration : underline;
			}
		}
		.header-info__row {
			padding : 0;
			margin  : 0
		}
	}
}