// -----------------------------------------------
//  Form config
// -----------------------------------------------

$input-background : #FFF;
$input-border : 1px solid $sf-blue;
$input-padding : 14px 18px;
$input-radius : 8px;
// Padding small
$input-padding-small : 8px 10px;
// Padding big
$input-padding-big : 15px 14px;

$input-font-size : 14px;

// Font size small
$input-font-size-small : 13px;
// Font size big
$input-font-size-big : 14px;

$input-color : #000;
$input-transition : border .3s ease;

// Placeholder
$input-placeholder : #000;

// Focus
$input-border-focus : $sf-blue;

// Error
$input-border-error : #F30;

// Success
$input-border-success : #3DB42C;

// Disabled
$input-border-disabled : #BCBCBC;
$input-background-disabled : #F9F9F9;
$input-color-disabled : #939393;

// Select style
$jq-select-dropdown-bg : #F7F7F7;
$jq-select-dropdown-color : #444;
$jq-select-dropdown-size : 14px;
$jq-select-dropdown-size-big : 14px;
$jq-select-dropdown-size-small : 13px;
$jq-select-dropdown-bg-hover : #E81313;
$jq-select-dropdown-color-hover : #FFF;
$jq-select-dropdown-padding : 12px 14px;
$jq-select-dropdown-padding-small : 8px 14px;
$jq-select-dropdown-padding-big : 17px 14px;
$jq-select-dropdown-border : 1px solid #E0E0E0;

// Type-2
$jq-select-dropdown-bg--type2 : #F7F7F7;
$jq-select-dropdown-color---type2 : #444;
$jq-select-dropdown-bg-hover---type2 : #E81313;
$jq-select-dropdown-color-hover---type2 : #FFF;
$jq-select-dropdown-border---type2 : #E0E0E0;

// Multiselect
$jq-multuselect-bg : #E81313;
$jq-multuselect-color : #FFF;

// Checkbox
$jb-checkbox-bg : transparent;
$jb-checkbox-border : #CDCDCD;
$jb-checkbox-bg-hover : transparent;
$jb-checkbox-border-hover : #CDCDCD;
$jb-checkbox-bg-active : transparent;
$jb-checkbox-border-active : #CDCDCD;
$jb-checkbox-bg-error : transparent;
$jb-checkbox-border-error : $input-border-error;
$jb-checkbox-bg-success : transparent;
$jb-checkbox-border-success : $input-border-success;

// Radio
$jb-radio-bg : transparent;
$jb-radio-border : #BCBCBC;
$jb-radio-bg-hover : transparent;
$jb-radio-border-hover : #BCBCBC;
$jb-radio-dot-hover : #444;
$jb-radio-bg-active : transparent;
$jb-radio-border-active : #BCBCBC;
$jb-radio-dot-active : #E81313;
$jb-radio-bg-error : transparent;
$jb-radio-border-error : $input-border-error;
$jb-radio-bg-success : transparent;
$jb-radio-border-success : $input-border-success;

// -----------------------------------------------
//  Form style
// -----------------------------------------------
input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="password"],
select,
textarea {
	background-color   : $input-background;
	border             : $input-border;
	border-radius      : $input-radius;
	outline            : 0;
	height             : auto;
	padding            : $input-padding;
	line-height        : 1.25;
	color              : $input-color;
	max-width          : 100%;
	transition         : $input-transition;
	font-size          : $input-font-size;
	-webkit-appearance : none;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	@include placeholder() {
		color   : $input-placeholder;
		opacity : 1;
	}

	&.focus,
	&:focus {
		border-color : $input-border-focus;
	}

	&.error {
		background-image    : url('../images/error.svg');
		background-repeat   : no-repeat;
		background-position : 100% 50%;
		border-color        : $input-border-error;
		padding-right       : 30px;

		.no-svg & {
			background-image : url('../images/error.png');
		}
	}

	&.success {
		background-image    : url('../images/success.svg');
		background-repeat   : no-repeat;
		background-position : 100% 50%;
		border-color        : $input-border-success;
		padding-right       : 30px;

		.no-svg & {
			background-image : url('../images/success.png');
		}
	}

	&.small {
		font-size : $input-font-size-small;
		padding   : $input-padding-small;
	}

	&.big {
		font-size : $input-font-size-big;
		padding   : $input-padding-big;
	}

	&.full-size {
		width : 100%;
	}

	&.disabled,
	&[disabled] {
		cursor           : default;
		background-color : $input-background-disabled;
		border-color     : $input-border-disabled;
		color            : $input-color-disabled;
	}
}

input[type="radio"],
input[type="checkbox"] {
	display        : inline-block;
	vertical-align : middle;
}

textarea {
	height   : auto;
	overflow : auto;

	&.error {
		background-position : 100% 13px;
	}

	&.success {
		background-position : 100% 10px;
	}
}

.b-form {
	legend {
		color       : $main;
		font-size   : 18px;
		font-weight : 700;
		padding-top : 20px;
		display     : block;
	}

	fieldset {
		margin  : 0;
		padding : 0;
	}
}

.is-checkbox {
	display     : block;
	position    : relative;
	overflow    : hidden;
	cursor      : pointer;
	line-height : 1.3;

	+ .is-checkbox {
		margin-top : 13px;
	}

	span {
		position       : relative;
		padding-left   : 25px;
		display        : inline-block;
		vertical-align : top;

		&:before {
			content       : '';
			position      : absolute;
			width         : 18px;
			height        : 18px;
			left          : 0;
			top           : 0;
			background    : #FFF;
			border        : 1px solid #D2DCE1;
			box-sizing    : border-box;
			border-radius : 4px;
		}

		&:after {
			content    : '';
			position   : absolute;
			top        : 3px;
			left       : 3px;
			transition : opacity .3s ease;
			opacity    : 0;
			@include svg-checkbox;
		}
	}

	input[type="checkbox"] {
		position : absolute;
		left     : -25px;
		top      : 0;
		opacity  : 0;

		&:checked + span {
			&:after {
				opacity : 1;
			}
		}

		&:disabled + span {
			background : #F7F7F7;
		}

		&:checked:disabled + span {
			background : #F7F7F7;

			&:after {
				opacity : 1;
				filter  : grayscale(100%);
			}
		}

		&:focus {
			&:before {
				border-color : rgba($sf-blue, .8);
			}
		}

		&:focus:disabled {
			&:before {
				border-color : #D2DCE1;
			}
		}
	}
}