.about-us {
	margin : 20px 0;
}

.about-us__inner {
	display   : flex;
	flex-flow : column nowrap;
	@media screen and (min-width : 580px) {
		display : block;
	}
}

.about-us__title {
	font-size     : 22px;
	font-weight   : 900;
	line-height   : 1.32;
	color         : $sf-black;
	margin-bottom : 10px;
	order         : 0;
	@media screen and (min-width : 580px) {
		font-size     : 38px;
		font-weight   : 900;
		margin-bottom : 48px;
		text-align    : center;
	}
}

.about-us__text {
	&.is-first {
		order : 1;
	}

	&.is-second {
		order : 3;
		@media screen and (min-width : 580px) {
			margin-top : 35px;
		}
	}

}

.about-us-years {
	order              : 2;
	background         : $sf-light-blue;
	border-radius      : 8px;
	padding            : 20px 25px;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	display            : flex;
	flex-flow          : row nowrap;
	justify-content    : space-between;
	align-items        : center;
	line-height        : 1.3;
	width              : 400px;
	max-width          : 100%;
	margin             : 30px auto;

	.b-icon-black-surfing {
		flex         : 0 0 64px;
		margin-right : 10px;
	}

	@media screen and (min-width : 580px) {
		border-top    : 1px solid #D8E1FD;
		margin-top    : 48px;
		width         : 100%;
		padding       : 68px 0 0;
		background    : transparent;
		border-radius : 0;
		display       : block;
		text-align    : center;
	}
}

.about-us-years__title {
	@media screen and (min-width : 580px) {
		font-size     : 26px;
		font-weight   : 900;
		line-height   : 1.12;
		margin-bottom : 48px;
	}
}

.about-us-years__count {
	font-size      : 16px;
	font-weight    : 900;
	line-height    : 1.63;
	letter-spacing : 2.8px;
	@media screen and (min-width : 580px) {
		display        : inline-block;
		vertical-align : top;
		font-size      : 24px;
	}

	b {
		font-size   : 54px;
		line-height : 41px;
		font-weight : bold;
		display     : block;
		@media screen and (min-width : 580px) {
			font-size      : 71px;
			line-height    : 66px;
			display        : inline-block;
			vertical-align : bottom;
		}
	}
}