.main-menu {
	position : relative;
	z-index  : 110;
	@include clearfix;

	ul {
		list-style : none;
		margin     : 0;
		padding    : 0;

		li {
			position : relative;
			z-index  : 1;
			@media screen and (min-width : 920px) {
				&:hover {
					z-index : 50;

					> ul {
						display   : block;
						animation : mainMenuDrop .4s ease;
					}
				}
			}
		}
	}

	> ul {
		> li {
			margin-top : 15px;

			&:first-child {
				margin-top : 0;
			}

			> a {
				font-size       : 14px;
				font-weight     : 700;
				line-height     : 1;
				text-decoration : none;
				color           : #000;
				transition      : color .3s ease;
			}

			// Hover style
			&.active,
			&:hover {
				> a {
					color : $sf-blue;
				}
			}

			// Parent style
			&.parent {
				> a {
				}

				&:hover {
					> a {
					}
				}
			}
		}
	}

	@media screen and (min-width : 920px) {
		> ul {
			font-size : 0;

			> li {
				display            : inline-block;
				vertical-align     : top;
				-webkit-box-sizing : border-box;
				-moz-box-sizing    : border-box;
				box-sizing         : border-box;
				margin             : 0;

				&.is-desktop-hidden {
					display    : none;
					visibility : hidden;

					> a {
						@include hide-text;
					}
				}

				> a {
					font-size       : 14px;
					font-weight     : 700;
					line-height     : 1;
					text-decoration : none;
					color           : $sf-grey;
					text-align      : center;
					display         : block;
					padding         : 12px 14px;
					border-radius   : 8px;
					transition      : background .3s ease, color .3s ease;
				}

				// Hover style
				&:hover {
					> a {
						color           : $sf-blue;
						text-decoration : none;
					}
				}

				&.active {
					> a {
						background      : $sf-light-blue;
						color           : $sf-blue;
						text-decoration : none;
					}
				}

				// Parent style
				&.parent {
					> a {
					}

					&:hover {
						> a {
						}
					}
				}
			}

			// Dropdown style
			ul {
				position         : absolute;
				top              : 100%;
				left             : 0;
				background       : #FFF;
				min-width        : 322px;
				display          : none;
				border-top       : 0;
				box-shadow       : 0 3px 6px rgba(0, 0, 0, .25);
				transform-origin : 0 0;

				ul {
					top  : 0;
					left : 100%;
				}

				a {
					display         : block;
					text-decoration : none;
					padding         : 10px 12px;
					transition      : background .4s ease, color .4s ease;
				}

				li:hover,
				li.active {
					> a {
						background : #EFFEF6;
					}
				}
			}
		}
	}
}

.main-menu__toggle {
	color            : #FFF;
	text-decoration  : none;
	position         : relative;
	overflow         : hidden;
	vertical-align   : top;
	display          : inline-block;
	visibility       : visible;
	border           : 0;
	background-color : transparent;
	padding          : 0;
	cursor           : pointer;
	outline          : 0;
	padding-left     : 20px;
	height           : 68px;
	margin           : -20px 0 -20px -20px;
	@media screen and (min-width : 920px) {
		display    : none;
		visibility : hidden;
	}

	.caret {
		display        : inline-block;
		vertical-align : middle;
		width          : 36px;
		position       : relative;

		i,
		&::after,
		&::before {
			width         : 100%;
			height        : 2px;
			display       : block;
			position      : absolute;
			border-radius : 1px;
			background    : $sf-blue;
			top           : 50%;
		}

		i {
			margin-top : -2px;
			width      : 27px;
			transition : opacity .3s ease, transform .3s ease;
		}

		&::after,
		&::before {
			content    : '';
			transform  : rotate(0deg);
			transition : transform .3s ease;
		}

		&::before {
			width      : 14px;
			margin-top : -11px;
		}

		&::after {
			margin-top : 7px;
		}
	}

	.text {
		display        : inline-block;
		vertical-align : middle;
		margin-left    : 5px;
	}


	&.is-active {
		//.caret {
		//	&::before {
		//		transform  : rotate(45deg);
		//		margin-top : 0;
		//		width      : 100%;
		//	}
		//
		//	&::after {
		//		transform  : rotate(-45deg);
		//		margin-top : 0;
		//	}
		//
		//	i {
		//		transform : translate3d(-100%, 0, 0);
		//		opacity   : 0;
		//		width     : 100%;
		//	}
		//}

	}
}

.main-menu__close {
	position   : absolute;
	top        : 0;
	right      : 0;
	padding    : 20px 20px 0 0;
	border     : 0;
	background : transparent;
	cursor     : pointer;
	outline    : 0;
	@media screen and (min-width : 920px) {
		display    : none;
		visibility : hidden;
	}
}

@keyframes mainMenuDrop {
	0% {
		opacity   : 0;
		transform : scaleY(0);
	}
	100% {
		opacity   : 1;
		transform : scaleY(1);
	}
}