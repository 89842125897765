.subscribe {
	margin-top : 30px;
	@media screen and (min-width : 620px) {
		background : $sf-light-blue;
		padding    : 68px 0 58px;
		margin-top : 68px;
	}
}

.subscribe__inner {
	background : $sf-light-blue;
	padding    : 33px 20px;
	display    : flex;
	flex-flow  : column nowrap;
	@media screen and (min-width : 620px) {
		padding          : 0;
		display          : block;
		background-color : transparent;
	}
}

.subscribe__title {
	font-size      : 16px;
	font-weight    : 700;
	line-height    : 1.5;
	letter-spacing : 1px;
	text-align     : center;
	margin-bottom  : 20px;
	@media screen and (min-width : 620px) {
		font-size      : 22px;
		font-weight    : 700;
		line-height    : 1.09;
		letter-spacing : 3.3px;
		margin-bottom  : 18px;
	}
}

.subscribe__subtitle {
	order      : 10;
	font-size  : 12px;
	text-align : center;
	margin-top : 20px;
	@media screen and (min-width : 620px) {
		font-size   : 14px;
		font-weight : 300;
		margin      : 0 0 34px;
	}
}

.subscribe-form__wrapper {
	display   : flex;
	flex-flow : column nowrap;
	@media screen and (min-width : 620px) {
		flex-flow       : row wrap;
		width           : 578px;
		margin          : auto;
		justify-content : space-between;
	}
}

input.subscribe-form__input {
	width      : 100%;
	border     : 1px solid #DCEFF4;
	text-align : center;
	@media screen and (min-width : 620px) {
		width : 48%;
		order : 0;
	}
}

.subscribe-form__terms {
	margin      : 12px 0;
	font-size   : 12px;
	line-height : 1.5;
	user-select : none;
	cursor      : pointer;
	@media screen and (min-width : 620px) {
		order      : 3;
		width      : 100%;
		text-align : center;
		margin-top : 19px;
	}
}

.subscribe-form__btn {
	width : 100%;
	@media screen and (min-width : 620px) {
		width : 48%;
		order : 1;
	}
}