.hero-block {
  margin-bottom : 40px;
  position      : relative;
  z-index       : 1;
  @media screen and (min-width : 920px) {
    .header + &,
    .header-search + & {
      margin-top : -20px;
      overflow   : hidden;

      &:after {
        content    : '';
        background : #FFF;
        position   : absolute;
        top        : 0;
        left       : 0;
        right      : 0;
        height     : 63px;
        z-index    : 5;
      }

      &:before {
        content    : '';
        box-shadow : 0 2px 11px #C5D1D3;
        position   : absolute;
        top        : 0;
        left       : 0;
        right      : 0;
        height     : 63px;
        z-index    : 1;
      }
    }
  }
}

.hero-block__menu {
  @include hide-text;
  @media screen and (min-width : 920px) {
    color              : #FFF;
    font-family        : $body-font;
    font-style         : normal;
    line-height        : normal;
    font-weight        : 700;
    font-size          : 14px;
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    border-radius      : 5px 5px 0 0;
    background         : linear-gradient(180.12deg, rgba(4, 40, 168, 0.59) 1.67%, rgba(68, 86, 165, 0.463323) 535.88%, rgba(234, 225, 225, 0.420069) 639.28%);
    backdrop-filter    : blur(10px);
    padding            : 13px 15px;
    text-align         : center;
    -webkit-box-sizing : border-box;
    -moz-box-sizing    : border-box;
    box-sizing         : border-box;
    height             : 63px; //hack
    .no-backdropfilter & {
      background : linear-gradient(180.12deg, rgba(4, 40, 168, 0.84) 1.67%, rgba(68, 86, 165, 0.84) 535.88%, rgba(234, 225, 225, 0.420069) 639.28%);
    }
    ul {
      margin     : 0;
      padding    : 0;
      list-style : none;

      li {
        margin         : 10px;
        display        : inline-block;
        vertical-align : top;

        &:after {
          display : none;
        }

        > a {
          text-decoration : none;
          color           : #FFF;
        }

        &:hover,
        &.active {
          > a {
            color           : #FFF;
            text-decoration : underline;
          }
        }
      }
    }
  }
  @media screen and (min-width : 980px) {
    ul {
      li {
        margin : 10px 18px;
      }
    }
  }
}

.hero-block__inner {
  height                  : 300px;
  display                 : flex;
  flex-flow               : column nowrap;
  justify-content         : center;
  border-radius           : 8px;
  background-repeat       : no-repeat;
  background-position     : 50% 0;
  -webkit-background-size : cover;
  background-size         : cover;
  padding                 : 25px;
  -webkit-box-sizing      : border-box;
  -moz-box-sizing         : border-box;
  box-sizing              : border-box;
  position                : relative;
  z-index                 : 20;
  @media screen and (min-width : 920px) {
    align-items : center;
    height      : 438px;
    padding-top : 63px;
  }
}

.hero-block__logo {
  width         : 100%;
  margin-bottom : 12px;
  @media screen and (min-width : 920px) {
    display    : none;
    visibility : hidden;
  }
}

.hero-block__title {
  font-size      : 28px;
  font-weight    : 900;
  line-height    : normal;
  color          : #FFF;
  letter-spacing : -0.3px;
  @media screen and (min-width : 920px) {
    font-weight    : 900;
    font-size      : 54px;
    line-height    : 66px;
    letter-spacing : -0.594px;
    padding-bottom : 42px;
    background     : url("../images/wave-hero.png") no-repeat 50% 100%;
    br {
      display : none;
    }
  }
}

.hero-block__footer {
  margin-top : 20px;
  text-align : center;
}

.hero-block__button {
  margin-top : 28px;
  text-align : center;
}

.b-instagram {
  .b-main__title {
    text-align : center;
    @media screen and (max-width : 640px) {
      margin-top : 24px;
    }
  }
}