$bullet-color : #000;
$bullet-color-active : #4954A0;
$bullet-size : 8px;

.swiper-pagination {
	position   : absolute;
	text-align : center;
	transition : 300ms opacity;
	transform  : translate3d(0, 0, 0);
	z-index    : 10;

	&.swiper-pagination-hidden {
		opacity : 0;
	}
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal .swiper-pagination-bullets {
	bottom : 10px;
	left   : 0;
	width  : 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
	overflow  : hidden;
	font-size : 0;

	.swiper-pagination-bullet {
		transform : scale(0.33);
		position  : relative;
	}

	.swiper-pagination-bullet-active {
		transform : scale(1);
	}

	.swiper-pagination-bullet-active-main {
		transform : scale(1);
	}

	.swiper-pagination-bullet-active-prev {
		transform : scale(0.66);
	}

	.swiper-pagination-bullet-active-prev-prev {
		transform : scale(0.33);
	}

	.swiper-pagination-bullet-active-next {
		transform : scale(0.66);
	}

	.swiper-pagination-bullet-active-next-next {
		transform : scale(0.33);
	}
}

.swiper-pagination-bullet {
	width         : $bullet-size;
	height        : $bullet-size;
	display       : inline-block;
	border-radius : 100%;
	background    : #000;
	opacity       : 0.2;

	button#{&} {
		border     : none;
		margin     : 0;
		padding    : 0;
		box-shadow : none;
		appearance : none;
	}

	.swiper-pagination-clickable & {
		cursor : pointer;
	}
}

.swiper-pagination-bullet-active {
	opacity    : 1;
	background : $bullet-color-active;
}

.swiper-container-vertical {
	.swiper-pagination-bullets {
		right     : 10px;
		top       : 50%;
		transform : translate3d(0px, -50%, 0);

		.swiper-pagination-bullet {
			margin  : 6px 0;
			display : block;
		}

		&.swiper-pagination-bullets-dynamic {
			top       : 50%;
			transform : translateY(-50%);
			width     : $bullet-size;

			.swiper-pagination-bullet {
				display    : inline-block;
				transition : 200ms transform, 200ms top;
			}
		}
	}
}

.swiper-container-horizontal {
	.swiper-pagination-bullets {
		.swiper-pagination-bullet {
			margin : 0 4px;
		}

		&.swiper-pagination-bullets-dynamic {
			left        : 50%;
			transform   : translateX(-50%);
			white-space : nowrap;

			.swiper-pagination-bullet {
				transition : 200ms transform, 200ms left;
			}
		}
	}
}

.swiper-pagination-lock {
	display : none;
}
