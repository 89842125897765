
/* montserrat-300 - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/montserrat-v13-cyrillic_latin-300.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/montserrat-v13-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/montserrat-v13-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/montserrat-v13-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/montserrat-v13-cyrillic_latin-800.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-800.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - cyrillic_latin */
@font-face {
	font-family: 'Font-Montserrat';
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/montserrat-v13-cyrillic_latin-900.eot'); /* IE9 Compat Modes */
	src: url('../fonts/montserrat-v13-cyrillic_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/montserrat-v13-cyrillic_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-900.woff') format('woff'), /* Modern Browsers */
	url('../fonts/montserrat-v13-cyrillic_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/montserrat-v13-cyrillic_latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}
