.social-block {
  font-size : 0;
  margin    : -12px;

  a {
    font-size      : 16px;
    display        : inline-block;
    vertical-align : top;
    width          : 28px;
    height         : 28px;
    margin         : 12px;
    background     : $sf-blue;
    transition     : background-color .3s ease;
    position       : relative;
    border-radius  : 50%;
    cursor         : pointer;

    &:hover {
      background : lighten($sf-blue, 10%);
    }

    i {
      position  : absolute;
      top       : 50%;
      left      : 50%;
      transform : translate(-50%, -50%);

      &.b-icon-vk {
        margin-left : -1px;
      }
    }
  }
}

.b__share {
  margin     : 2rem auto;
  text-align : center;
  .b__share-title {
    font-weight : 900;
    font-size   : 1.5rem;
  }
}