// -----------------------------------------------
//  Form config
// -----------------------------------------------
// Error
$form-label-color : #444;
$form-label-size : 14px;
$form-label-weight : 400;
$form-label-margin : 0 0 10px;
$form-error-color : #F30;
$form-error-size : 12px;
$form-error-weight : 400;

// Error Type-2
$form-error-type-2-bg : #F30;
$form-error-type-2-radius : 1px;
$form-error-type-2-color : #FFF;
$form-error-type-2-size : 12px;
$form-error-type-2-weight : 400;
$form-error-type-2-padding : 3px 7px;
$form-error-type-2-marrgin : -5px 15px 5px;

// Hint
$form-hint-color : #444;
$form-hint-size : 12px;
$form-hint-weight : 400;
$form-hint-style : italic;
$form-hint-margin : 5px 0 0;

// Grid
$form-grid : 7px;

// -----------------------------------------------
//  Form style
// -----------------------------------------------
.b-form {
	position : relative;

	.b-grid {
		margin : -$form-grid;
	}

	.b-grid__item {
		padding : $form-grid;
	}
}

.b-form__row {
	margin-top : 15px;
	position   : relative;
	@media screen and (min-width : 920px) {
		margin-top : 28px;
	}

	&:first-child {
		margin-top : 0;
	}

	input[type="text"],
	input[type="email"],
	input[type="search"],
	input[type="number"],
	input[type="tel"],
	input[type="password"],
	textarea {
		width : 100%;
	}

	label {
		display     : block;
		cursor      : pointer;
		color       : $form-label-color;
		font-size   : $form-label-size;
		margin      : $form-label-margin;
		font-weight : $form-label-weight;
	}
}

.b-form__input {
	position : relative;
	z-index  : 1;
}

.b-form__hint {
	position    : relative;
	z-index     : 2;
	color       : $form-hint-color;
	font-size   : $form-hint-size;
	font-weight : $form-hint-weight;
	font-style  : $form-hint-style;
	margin      : $form-hint-margin;
}

.b-form__error {
	margin-top  : 5px;
	color       : $form-error-color;
	font-size   : $form-error-size;
	font-weight : $form-error-weight;
	z-index     : 3;
	position    : relative;
	display     : none;

	&.visible {
		display : block;
	}
}

.form-contact {
	outline : 0;

	@media screen and (min-width : 500px) {
		width     : 427px;
		max-width : 100%;
		margin    : auto;
	}

	form {
		outline : 0;
	}

	input {
		text-align : center;
	}
}

.form-contact__submit {
	text-align : center;

	.b-btn {
		width : 100%;
		@media screen and (min-width : 400px) {
			width     : 320px;
			max-width : 100%;
		}
	}
}

.form-contact__terms {
	text-align : center;

	.is-checkbox span {
		text-align : left;
	}
}